import React, { useState } from "react"
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  TextField,
  CircularProgress,
  Alert,
  Box,
  IconButton,
  Typography,
} from "@mui/material"
import CloseIcon from "@mui/icons-material/Close"
import CloudUploadIcon from "@mui/icons-material/CloudUpload"
import DeleteIcon from "@mui/icons-material/Delete"
import { styled } from "@mui/material/styles"
import * as yup from "yup"
const URL = process.env.REACT_APP_API_URL;

const StyledDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(3),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(2),
  },
}))

const validationSchema = yup.object({
  shopDescription: yup
    .string()
    .required("Shop description is required")
    .min(3, "Description should be at least 3 characters")
    .max(500, "Description should not exceed 200 characters"),
})

const ShopManagement = ({ open, handleClose, shop, onShopUpdated, onShopDeleted }) => {
  const [formData, setFormData] = useState({
    shopDescription: shop.shopDescription,
  })
  const [selectedFile, setSelectedFile] = useState(null)
  const [errors, setErrors] = useState({})
  const [loading, setLoading] = useState(false)
  const [submitError, setSubmitError] = useState(null)
  const [deleteConfirmOpen, setDeleteConfirmOpen] = useState(false)

  const handleInputChange = (e) => {
    const { name, value } = e.target
    setFormData((prev) => ({
      ...prev,
      [name]: value,
    }))
    if (errors[name]) {
      setErrors((prev) => ({
        ...prev,
        [name]: "",
      }))
    }
  }

  const handleFileSelect = (event) => {
    setSelectedFile(event.target.files[0])
  }

  const handleUpdate = async () => {
    try {
      setSubmitError(null)
      await validationSchema.validate(formData, { abortEarly: false })

      setLoading(true)

      let shopLogo = shop.shopLogo

      if (selectedFile) {
        const imageFormData = new FormData()
        imageFormData.append("image", selectedFile)

        const imageResponse = await fetch(`${URL}/shop/add_image`, {
          method: "POST",
          body: imageFormData,
        })

        const imageData = await imageResponse.json()

        if (imageData.status) {
          shopLogo = imageData.fileUrl
        } else {
          throw new Error(imageData.message || "Failed to upload image")
        }
      }

      const response = await fetch(`${URL}/shop/update_shop`, {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          ...formData,
          shopLogo,
          shopId: shop?.shopId,
          providerId: shop.providerId,
        }),
      })

      const data = await response.json()

      if (data.status) {
        onShopUpdated(data.shop)
        handleClose()
      } else {
        throw new Error(data.message || "Failed to update shop")
      }
    } catch (err) {
      if (err instanceof yup.ValidationError) {
        const validationErrors = {}
        err.inner.forEach((error) => {
          validationErrors[error.path] = error.message
        })
        setErrors(validationErrors)
      } else {
        setSubmitError(err.message)
      }
    } finally {
      setLoading(false)
    }
  }

  const handleDelete = async () => {
    try {
      setLoading(true)
      const response = await fetch(`${URL}/shop/delete_shop/${shop?.shopId}`, {
        method: "DELETE",
      })

      const data = await response.json()

      if (data.status) {
        onShopDeleted(shop._id)
        handleClose()
      } else {
        throw new Error(data.message || "Failed to delete shop")
      }
    } catch (err) {
      setSubmitError(err.message)
    } finally {
      setLoading(false)
      setDeleteConfirmOpen(false)
    }
  }

  return (
    <StyledDialog open={open} onClose={handleClose} maxWidth="sm" fullWidth>
      <DialogTitle sx={{ m: 0, p: 2, display: "flex", justifyContent: "space-between", alignItems: "center" }}>
        Manage Shop
        <IconButton aria-label="close" onClick={handleClose} sx={{ color: "grey.500" }}>
          <CloseIcon />
        </IconButton>
      </DialogTitle>

      <DialogContent dividers>
        {submitError && (
          <Alert severity="error" sx={{ mb: 2 }}>
            {submitError}
          </Alert>
        )}

        <Box component="form" noValidate sx={{ display: "flex", flexDirection: "column", gap: 2 }}>
          <TextField
            name="shopDescription"
            label="Shop Description"
            multiline
            rows={3}
            value={formData.shopDescription}
            onChange={handleInputChange}
            error={!!errors.shopDescription}
            helperText={errors.shopDescription}
            disabled={loading}
          />

          <input
            type="file"
            accept="image/*"
            onChange={handleFileSelect}
            style={{ display: "none" }}
            id="shop-logo-upload"
          />
          <label htmlFor="shop-logo-upload">
            <Button variant="outlined" component="span" startIcon={<CloudUploadIcon />} disabled={loading}>
              {selectedFile ? "Change Logo" : "Upload New Logo"}
            </Button>
          </label>
          {selectedFile && (
            <Typography variant="body2" color="textSecondary">
              Selected file: {selectedFile.name}
            </Typography>
          )}
        </Box>
      </DialogContent>

      <DialogActions>
        <Button onClick={() => setDeleteConfirmOpen(true)} color="error" startIcon={<DeleteIcon />} disabled={loading}>
          Delete Shop
        </Button>
        <Button onClick={handleClose} disabled={loading}>
          Cancel
        </Button>
        <Button
          onClick={handleUpdate}
          variant="contained"
          disabled={loading}
          startIcon={loading && <CircularProgress size={20} />}
        >
          {loading ? "Updating..." : "Update Shop"}
        </Button>
      </DialogActions>

      <Dialog open={deleteConfirmOpen} onClose={() => setDeleteConfirmOpen(false)}>
        <DialogTitle>Confirm Delete</DialogTitle>
        <DialogContent>Are you sure you want to delete this shop? This action cannot be undone.</DialogContent>
        <DialogActions>
          <Button onClick={() => setDeleteConfirmOpen(false)}>Cancel</Button>
          <Button onClick={handleDelete} color="error" autoFocus>
            Delete
          </Button>
        </DialogActions>
      </Dialog>
    </StyledDialog>
  )
}

export default ShopManagement

