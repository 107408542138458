import React, { useState } from 'react';
import { DataGrid, GridToolbar } from '@mui/x-data-grid';
import { Typography, Button, Stack, TextField } from '@mui/material';
import AddIcon from "@mui/icons-material/Add";
import CsvUpload from "../modals/CsvUploadModal";
import VisibilityIcon from '@mui/icons-material/Visibility';
import { useNavigate } from "react-router-dom";

import {
  GridToolbarContainer,
  GridToolbarColumnsButton,
  GridToolbarFilterButton,
  GridToolbarExport,
} from '@mui/x-data-grid';

const Table = (props) => {
  const [item, setItem] = useState("");
  const [open, setOpen] = React.useState(false);
  const navigate = useNavigate();
  
  const CustomToolbar = () => {
    return (
      <GridToolbarContainer>
        <GridToolbarColumnsButton />
        <GridToolbarFilterButton />
        <GridToolbarExport 
          printOptions={{ disableToolbarButton: true }} 
          csvOptions={{
            fileName: "vendo"+JSON.stringify(props.pageTitle),
            delimiter: ';',
            utf8WithBom: true,
          }}
        />
      </GridToolbarContainer>
    );
  }
  
  const handleOpenClose = (op = false) => {
    setOpen(op);
  }

  // Process columns to handle custom actions
  const processedColumns = props.columns.map(column => {
    if (column.field === 'actions') {
      return {
        ...column,
        renderCell: (params) => {
          const cellValue = column.renderCell(params);
          
          if (cellValue?.type === 'actions') {
            return (
              <Stack direction="row" spacing={1}>
                {props.pageTitle === "Provider" && (
                  <Button
                    variant="outlined"
                    size="small"
                    startIcon={<VisibilityIcon />}
                    onClick={() => navigate(`/providers/${cellValue.providerId}/shops`)}
                  >
                    View Shops
                  </Button>
                )}
              </Stack>
            );
          }
          return cellValue;
        }
      };
    }
    return column;
  });

  return (
    <>
      <Stack
        direction="row"
        justifyContent="space-between"
        alignItems="flex-end"
        spacing={0}
        sx={{
          paddingTop: "2em",
          paddingBottom: "3em",
        }}
      >
        <Typography variant="h4" noWrap component="div">
          {props.title}
        </Typography>
        {!["Transaction"].includes(props.pageTitle) && (
          <Button
            variant="contained"
            size="large"
            startIcon={<AddIcon />}
            onClick={(e) => {
              switch (props.pageTitle) {
                case "Provider":
                  navigate("/add_provider", {
                    state: { pageTitle: props.pageTitle, fieldKey: props.fieldKey, dataKey: props.dataKey }
                  });
                  break;
                default:
                  navigate("/adddata", {
                    state: { pageTitle: props.pageTitle, fieldKey: props.fieldKey, dataKey: props.dataKey }
                  });
                  break;
              }
            }}
          >
            ADD new {props.pageTitle}
          </Button>
        )}
      </Stack>

      <TextField
        className={`search_${props.dataKey}`}
        id={`search_${props.dataKey}`}
        name={`search_${props.dataKey}`}
        variant="outlined"
        type={"text"}
        label={`Search ${props.title}`}
        sx={{
          marginBottom: "3em",
          width: "20em"
        }}
        value={item}
        onChange={(e) => {
          setItem(e.currentTarget.value);
          switch (props.pageTitle) {
            case "Admin":
              props.searchUsers(e.currentTarget.value);
              break;
            case "Customer":
              props.searchCustomers(e.currentTarget.value);
              break;
            case "Provider":
              props.searchProviders(e.currentTarget.value);
              break;
            case "Voucher":
              props.searchVouchers(e.currentTarget.value);
              break;
            case "Product":
              props.searchProducts(e.currentTarget.value);
              break;
            case "Transaction":
              props.searchTransaction(e.currentTarget.value);
              break;
            case "Redeemed vouchers":
              props.searchTransactionVouchers(e.currentTarget.value);
              break;
            default:
              break;
          }
        }}
      />

      {["Product"].includes(props.pageTitle) && (
        <Button
          variant="outlined"
          size="large"
          sx={{
            marginLeft: "2em"
          }}
          onClick={() => handleOpenClose(!open)}
        >
          Upload CSV
        </Button>
      )}
      
      <CsvUpload open={open} handleOpenClose={handleOpenClose} {...props} />

      <div style={{ height: "68.1vh", width: '100%' }}>
        <DataGrid
          rows={props.rows}
          columns={processedColumns}
          pageSize={props.rowsPerPage}
          rowsPerPageOptions={[props.pageSize]}
          disableSelectionOnClick
          components={{ Toolbar: CustomToolbar }}
          componentsProps={{ toolbar: { printOptions: { disableToolbarButton: false } } }}
          onCellClick={(params, event) => {
            event.defaultMuiPrevented = true;
            if (params.field === "id") {
              switch (props.pageTitle) {
                case "Provider":
                  navigate("/update_provider", {
                    state: { pageTitle: props.pageTitle, id: params.row.id, fieldKey: props.fieldKey, dataKey: props.dataKey }
                  });
                  break;
                case "Redeemed vouchers":
                  navigate("/transactionsVoucher", {
                    state: { pageTitle: props.pageTitle, id: params.row.id, fieldKey: props.fieldKey, dataKey: props.dataKey }
                  });
                  break;
                default:
                  navigate("/updatedata", {
                    state: { pageTitle: props.pageTitle, id: params.row.id, fieldKey: props.fieldKey, dataKey: props.dataKey }
                  });
                  break;
              }
            }
          }}
        />
      </div>
    </>
  );
}

export default Table;