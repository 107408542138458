import React, { useState } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  TextField,
  CircularProgress,
  Alert,
  Box,
  IconButton,
  Typography,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import { styled } from '@mui/material/styles';
import * as yup from 'yup';
const URL = process.env.REACT_APP_API_URL;

const StyledDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialogContent-root': {
    padding: theme.spacing(3),
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(2),
  },
}));

const validationSchema = yup.object({
  shopDescription: yup
    .string()
    .required('Shop description is required')
    .min(3, 'Description should be at least 3 characters')
    .max(500, 'Description should not exceed 200 characters'),
});

const AddShopModal = ({ open, handleClose, providerId, onShopAdded }) => {
  const [formData, setFormData] = useState({
    shopDescription: '',
  });
  const [selectedFile, setSelectedFile] = useState(null);
  const [errors, setErrors] = useState({});
  const [loading, setLoading] = useState(false);
  const [submitError, setSubmitError] = useState(null);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({
      ...prev,
      [name]: value,
    }));
    if (errors[name]) {
      setErrors((prev) => ({
        ...prev,
        [name]: '',
      }));
    }
  };

  const handleFileSelect = (event) => {
    setSelectedFile(event.target.files[0]);
  };

  const handleSubmit = async () => {
    try {
      setSubmitError(null);
      await validationSchema.validate(formData, { abortEarly: false });
      
      setLoading(true);

      let shopLogo = '';

      // First upload the image if one is selected
      if (selectedFile) {
        const imageFormData = new FormData();
        imageFormData.append('image', selectedFile);

        const imageResponse = await fetch(`${URL}/shop/add_image`, {
          method: 'POST',
          body: imageFormData,
        });

        const imageData = await imageResponse.json();

        if (imageData.status) {
          shopLogo = imageData.fileUrl;
        } else {
          throw new Error(imageData.message || 'Failed to upload image');
        }
      }

      // Then create the shop with the uploaded image URL
      const response = await fetch(`${URL}/shop/add_shop`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          ...formData,
          shopLogo,
          providerId,
        }),
      });

      const data = await response.json();

      if (data.status) {
        onShopAdded(data.shop);
        handleClose();
      } else {
        throw new Error(data.message || 'Failed to add shop');
      }
    } catch (err) {
      if (err instanceof yup.ValidationError) {
        const validationErrors = {};
        err.inner.forEach((error) => {
          validationErrors[error.path] = error.message;
        });
        setErrors(validationErrors);
      } else {
        setSubmitError(err.message);
      }
    } finally {
      setLoading(false);
    }
  };

  return (
    <StyledDialog open={open} onClose={handleClose} maxWidth="sm" fullWidth>
      <DialogTitle sx={{ m: 0, p: 2, display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
        Add New Shop
        <IconButton
          aria-label="close"
          onClick={handleClose}
          sx={{ color: 'grey.500' }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      
      <DialogContent dividers>
        {submitError && (
          <Alert severity="error" sx={{ mb: 2 }}>
            {submitError}
          </Alert>
        )}
        
        <Box component="form" noValidate sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
          <TextField
            name="shopDescription"
            label="Shop Description"
            multiline
            rows={3}
            value={formData.shopDescription}
            onChange={handleInputChange}
            error={!!errors.shopDescription}
            helperText={errors.shopDescription}
            disabled={loading}
          />
          
          <input
            type="file"
            accept="image/*"
            onChange={handleFileSelect}
            style={{ display: 'none' }}
            id="shop-logo-upload"
          />
          <label htmlFor="shop-logo-upload">
            <Button
              variant="outlined"
              component="span"
              startIcon={<CloudUploadIcon />}
              disabled={loading}
            >
              Upload Shop Logo
            </Button>
          </label>
          {selectedFile && (
            <Typography variant="body2" color="textSecondary">
              Selected file: {selectedFile.name}
            </Typography>
          )}
        </Box>
      </DialogContent>
      
      <DialogActions>
        <Button onClick={handleClose} disabled={loading}>
          Cancel
        </Button>
        <Button
          onClick={handleSubmit}
          variant="contained"
          disabled={loading}
          startIcon={loading && <CircularProgress size={20} />}
        >
          {loading ? 'Creating...' : 'Create Shop'}
        </Button>
      </DialogActions>
    </StyledDialog>
  );
};

export default AddShopModal;