import React, { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import {
    Box,
    Typography,
    Container,
    IconButton,
    CircularProgress,
    Alert,
    Button,
    Paper,
    Divider,
    Fade,
    Chip,
    useTheme,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import StorefrontIcon from "@mui/icons-material/Storefront";
import EditIcon from "@mui/icons-material/Edit";
import AddIcon from "@mui/icons-material/Add";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import PhoneIcon from "@mui/icons-material/Phone";
import ShopManagement from "./ShopManagement";
import AddShopModal from "./AddShopModal";
import axios from "axios";
const URL = process.env.REACT_APP_API_URL;


const StyledContainer = styled(Container)(({ theme }) => ({
    padding: theme.spacing(4),
    [theme.breakpoints.down("sm")]: {
        padding: theme.spacing(2),
    },
}));

const ShopCard = styled(Paper)(({ theme }) => ({
    padding: theme.spacing(4),
    marginTop: theme.spacing(3),
    borderRadius: theme.shape.borderRadius * 2,
    border: `1px solid ${theme.palette.grey[200]}`,
    boxShadow: '0 4px 6px rgba(0, 0, 0, 0.05), 0 10px 20px rgba(0, 0, 0, 0.1)',
    transition: "transform 0.2s ease-in-out, box-shadow 0.2s ease-in-out",
    "&:hover": {
        transform: "translateY(-4px)",
        boxShadow: '0 6px 12px rgba(0, 0, 0, 0.1), 0 12px 24px rgba(0, 0, 0, 0.12)',
    },
}));

const ShopLogo = styled("img")(({ theme }) => ({
    width: "120px",
    height: "120px",
    borderRadius: "16px",
    objectFit: "cover",
    marginRight: theme.spacing(3),
    boxShadow: "0 4px 12px rgba(0, 0, 0, 0.08)",
    [theme.breakpoints.down("sm")]: {
        width: "80px",
        height: "80px",
        marginRight: theme.spacing(2),
    },
}));

const ShopInfo = styled(Box)(({ theme }) => ({
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    flex: 1,
}));

const InfoChip = styled(Chip)(({ theme }) => ({
    margin: theme.spacing(0.5),
    borderRadius: "8px",
}));

const ActionButton = styled(Button)(({ theme }) => ({
    borderRadius: "12px",
    padding: `${theme.spacing(1.5)} ${theme.spacing(3)}`,
    textTransform: "none",
    fontWeight: 600,
}));

const ShopsListing = () => {
    const { providerId } = useParams();
    const navigate = useNavigate();
    const theme = useTheme();
    const [shop, setShop] = useState(null);
    const [isManagementModalOpen, setIsManagementModalOpen] = useState(false);
    const [isAddShopModalOpen, setIsAddShopModalOpen] = useState(false);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    const fetchShop = async () => {
        if (!providerId) {
            setError("Provider ID is required")
            setLoading(false)
            return
        }

        try {
            setLoading(true)            
            const response = await axios.get(`${URL}/shop/get_shop_by_provider_id/${providerId}`,  {
                headers: {
                    "Content-Type": "multipart/form-data",
                },
            })

            if (response.data.status) {
                setShop(response.data.data)
            }else if(response.data.message == "No shop found"){

            } else {
                throw new Error(response.data.message || "Failed to fetch shop")
            }
        } catch (err) {
            setError(err.message || "An error occurred while fetching the shop")
        } finally {
            setLoading(false)
        }
    }
    useEffect(() => {

        fetchShop()
    }, [providerId])

    const handleBack = () => {
        navigate(-1)
    }

    const handleOpenManagementModal = () => {
        setIsManagementModalOpen(true)
    }

    const handleCloseManagementModal = () => {
        setIsManagementModalOpen(false)
    }

    const handleShopUpdated = (updatedShop) => {
        fetchShop()
        // setShop(updatedShop)
    }

    const handleShopDeleted = () => {
        setShop(null)
    }

    const handleOpenAddShopModal = () => {
        setIsAddShopModalOpen(true)
    }

    const handleCloseAddShopModal = () => {
        setIsAddShopModalOpen(false)
    }

    const handleShopAdded = (newShop) => {
        fetchShop()
        // setShop(newShop)
    }

    if (loading) {
        return (
            <StyledContainer maxWidth="md">
                <Box display="flex" justifyContent="center" alignItems="center" minHeight="80vh">
                    <CircularProgress size={48} thickness={4} />
                </Box>
            </StyledContainer>
        );
    }

    if (error) {
        return (
            <StyledContainer maxWidth="md">
                <Box sx={{ mb: 4, display: "flex", alignItems: "center" }}>
                    <IconButton onClick={() => navigate(-1)} sx={{ mr: 2 }}>
                        <ArrowBackIcon />
                    </IconButton>
                    <Typography variant="h4" sx={{ fontWeight: 700 }}>
                        Shop Details
                    </Typography>
                </Box>
                <Fade in>
                    <Alert
                        severity="error"
                        variant="filled"
                        sx={{ borderRadius: 2 }}
                    >
                        {error}
                    </Alert>
                </Fade>
            </StyledContainer>
        );
    }

    return (
        <StyledContainer maxWidth="lg">
            <Box sx={{ mb: 4, display: "flex", alignItems: "center", justifyContent: "space-between" }}>
                <Box sx={{ display: "flex", alignItems: "center" }}>
                    <IconButton
                        onClick={() => navigate(-1)}
                        sx={{
                            mr: 2,
                            backgroundColor: theme.palette.grey[100],
                            "&:hover": {
                                backgroundColor: theme.palette.grey[200],
                            },
                        }}
                    >
                        <ArrowBackIcon />
                    </IconButton>
                    <Typography variant="h4" sx={{ fontWeight: 700 }}>
                        Shop Details
                    </Typography>
                </Box>
                {/* {!shop && (
                    <ActionButton
                        variant="contained"
                        startIcon={<AddIcon />}
                        onClick={() => setIsAddShopModalOpen(true)}
                        sx={{
                            backgroundColor: theme.palette.primary.main,
                            "&:hover": {
                                backgroundColor: theme.palette.primary.dark,
                            },
                        }}
                    >
                        Add New Shop
                    </ActionButton>
                )} */}
            </Box>

            {!shop ? (
                <Fade in>
                    <Box
                        display="flex"
                        flexDirection="column"
                        alignItems="center"
                        justifyContent="center"
                        minHeight="60vh"
                        sx={{
                            backgroundColor: theme.palette.grey[50],
                            borderRadius: 4,
                            padding: 4,
                        }}
                    >
                        <StorefrontIcon sx={{ fontSize: 96, color: theme.palette.grey[300], mb: 3 }} />
                        <Typography variant="h5" color="text.secondary" gutterBottom sx={{ fontWeight: 600 }}>
                            No Shop Found
                        </Typography>
                        <Typography
                            variant="body1"
                            color="text.secondary"
                            align="center"
                            sx={{ maxWidth: 400, mb: 4 }}
                        >
                            There is currently no shop associated with this provider. Add a new shop to get started.
                        </Typography>
                        <ActionButton
                            variant="outlined"
                            startIcon={<AddIcon />}
                            onClick={() => setIsAddShopModalOpen(true)}
                        >
                            Create Your First Shop
                        </ActionButton>
                    </Box>
                </Fade>
            ) : (
                <Fade in>
                    <ShopCard elevation={0}>
                        <Box display="flex" alignItems="flex-start">
                            <ShopLogo src={shop.shopLogo} alt={shop.providerName} />
                            <ShopInfo>
                                <Typography variant="h5" gutterBottom sx={{ fontWeight: 700 }}>
                                    {shop.providerName}
                                </Typography>
                                <Typography
                                    variant="body1"
                                    color="text.secondary"
                                    paragraph
                                    sx={{ mb: 3 }}
                                >
                                    {shop.shopDescription}
                                </Typography>
                                <Box sx={{ display: "flex", flexWrap: "wrap", gap: 1, mb: 2 }}>
                                    <InfoChip
                                        icon={<LocationOnIcon />}
                                        label={`${shop.address}, ${shop.postcode}`}
                                        variant="outlined"
                                    />

                                </Box>
                            </ShopInfo>
                        </Box>
                        <Divider sx={{ my: 3 }} />
                        <Box sx={{ display: "flex", justifyContent: "flex-end", gap: 2 }}>
                            <ActionButton
                                variant="outlined"
                                startIcon={<EditIcon />}
                                onClick={() => setIsManagementModalOpen(true)}
                            >
                                Manage Shop
                            </ActionButton>
                        </Box>
                    </ShopCard>
                </Fade>
            )}

            {shop && (
                <ShopManagement
                    open={isManagementModalOpen}
                    handleClose={() => setIsManagementModalOpen(false)}
                    shop={shop}
                    onShopUpdated={handleShopUpdated}
                    onShopDeleted={handleShopDeleted}
                />
            )}

            <AddShopModal
                open={isAddShopModalOpen}
                handleClose={() => setIsAddShopModalOpen(false)}
                providerId={providerId}
                onShopAdded={handleShopAdded}
            />
        </StyledContainer>
    );
};

export default ShopsListing;