import React from 'react'
import Table from '.';
import { connect } from 'react-redux';
import { mapStateToProps, mapDispachToProps } from "../../store/MapToProps";
import { providerStructure } from '../../data_formats';

const ProviderTable = (props) => {
  React.useEffect(() => {
    props.getAllProviders()
  }, [])
  
  return (
    <Table
      title={"Providers"}
      pageTitle={"Provider"}
      columns={providerStructure}
      rows={props.providers.providers}
      rowsPerPage={50}
      pageSize={10}
      fieldKey={"providerStructure"}
      dataKey={"providers"}
      {...props}
    />
  )
}

export default connect(mapStateToProps, mapDispachToProps)(ProviderTable);