import { useState, useEffect } from "react";
import _ from 'underscore';
import { TextField, Switch, Button, FormControlLabel, Fab, Typography, Stack, Paper, Box ,Select,MenuItem} from "@mui/material"
import AddIcon from "@mui/icons-material/Add";
import { useLocation } from 'react-router-dom';
import structures from '../../data_formats';
import notRequired from '../../data_formats/notRequiredFields'

import IconButton from '@mui/material/IconButton';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputLabel from '@mui/material/InputLabel';
import InputAdornment from '@mui/material/InputAdornment';
import FormControl from '@mui/material/FormControl';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import Loader from "../common/loder";

import { connect } from 'react-redux';
import { mapStateToProps, mapDispachToProps } from "../../store/MapToProps";
import axios from 'axios';
import moment from 'moment'
import { getAllcategory } from "../../store/actions";

let values = {}
let fields = []
let catedata=[];
let ignoredFields = {
  emailVerified: false,
  deactivate: false
}

const getFilteredField = (key) => {
 
  let array1 = notRequired[key].map(function (item) {
    return item;
  });

  let filtered = fields.filter(function (item) {
    return array1.indexOf(item) === -1;
  });

  console.log("total : ", fields)
  console.log("notRequired : ", notRequired[key])
  console.log("filtered : ", filtered)

  return filtered
}

const checkElements = (target, array) => {
  const checker = target.every(v => array.includes(v));
  console.log("checker1", target)
  console.log("checker2", array)

  return checker
}

const handleSubmit = (props, category) => {
  values = {
    ...values,
    ...ignoredFields
  }

  console.log("values", values);

  let keys = Object.keys(values)

  // let copyFields = JSON.parse(JSON.stringify(fields))
  // copyFields = copyFields.filter(item => item !== "id")
  // keys = keys.sort()
  // copyFields = copyFields.sort()

  // const isEqual = _.isEqual(keys, copyFields)
   console.log('kk',keys);
  // console.log(copyFields)

  const filtered = getFilteredField(category)
  
  const checker = checkElements(filtered, keys)
  console.log(filtered, checker)

  // return false

  // if (!checker) {
  //   alert(filtered.join("\n") + "\n - Fields are mandatory")
  //  // return false
  // }
  if(fields.includes("email")){

    if(values.email){
      if(values.email === " " || values.email === null){
        alert("Please provide your email id.")
      }
  
      const validateEmail = () => {
        var regexp = /^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$/;
        return regexp.test(values.email.trim());
      };
    
      if (!validateEmail()) {
        alert("Please enter valid email id.")
        return;
      }
    }else {
      alert("Please provide your email.")
      return false
    }

  }


  if (fields.includes("password")) {
    if (values.password) {

      const validatePassword = () => {
        var regexp = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/;
        return regexp.test(values.password.trim());
      };
    
      if (!validatePassword()) {
        alert("Please enter minimum eight characters, at least one uppercase letter, one lowercase letter, one number and one special character.")
        return;
      }

      if (values.verifyPassword) {
        if (!(values.password === values.verifyPassword)) {
          alert("Password didn't match")
          return false
        }
      } else {
        alert("Please provide password again")
        return false
      }
    } else {
      alert("Please provide password")
      return false
    }
  }

  var formData = new FormData();

  keys.forEach(e => {
    formData.append(e, values[e])
  });
  switch (category) {
    case "Admin":
      props.enableLoder()
      props.addUser(formData, { 'Content-Type': 'multipart/form-data' })
      break;
    case "Customer":
      props.addCustomer(formData, { 'Content-Type': 'multipart/form-data' })
      break;
    case "Provider":
      props.addProvider(formData, { 'Content-Type': 'multipart/form-data' })
      break;
    case "Voucher":
      props.addVoucher(formData, { 'Content-Type': 'multipart/form-data' })
      break;
    case "Product":
      props.addProduct(formData, { 'Content-Type': 'multipart/form-data' })
      break;
      case "Category":
        props.addcategory(values, { 'Content-Type': 'application/json' })
        break;
    default:
      break;
  }
}

const GenerateFields = ({ location, props }) => {

  let handleReset;
  let currentDate = moment(new Date()).format('YYYY-MM-DD');

  const GetField = ({ structure, id }) => {
    const [filename, setFilename] = useState({});
    const [item, setItem] = useState({})

    handleReset = () => {
      console.log("reseting state")
      setFilename({})
      setItem({})
      values = {}
    }

    useEffect(() => {
      let priority = localStorage.getItem("priority") ? Number(localStorage.getItem("priority")) : -1
      let user = localStorage.getItem("uid") ? JSON.parse(localStorage.getItem("uid")).user : {}

      if ([2].includes(priority) && structure.field === "pid") {
        setItem({ ...item, pid: user.id })
        values = {
          ...values,
          pid: user.id
        }
        var eleman = document.getElementById(`${id}_pid`);
        eleman.setAttribute("readonly", true);
      }
    }, [])
    const [data, setData] = useState([])
    useEffect(() => {
      if(data.length<=0 && structure.inputType=='select'){
      axios
        .get(process.env.REACT_APP_API_URL + "/getcategory?type="+structure.data)
        .then((res) => {
          setData(res.data.result)
        })
      }
    })
   
console.log(getAllcategory());
    switch (structure.inputType) {
      case "text":
        return (
          <TextField
            className={`${id}_${structure.field}`}
            id={`${id}_${structure.field}`}
            name={`${id}_${structure.field}`}
            type={structure.inputType}
            label={structure.headerName}
            autoComplete='false'
            variant="outlined"
            sx={{
              marginBottom: "2em",
              width: "30em",
              display: structure.hideInput ? "none" : ""
            }}
            value={item[structure.field] || ""}
            onChange={(e) => {
              let key = structure.field
              setItem({ ...item, [key]: e.currentTarget.value })
              values = { ...values, [key]: e.currentTarget.value }
            }}
            disabled={structure.isDisabled ? true : false}
          />
        )
        case "select1":
          return (
           
            <FormControl style={{width:'50%',marginBottom:10}}>
              
  <InputLabel id="demo-simple-select-label">Category Type</InputLabel>
            <Select
           
            onChange={(e) => {
              
              let key = structure.field
              setItem({ ...item, [key]: e.target.value })
              values = { ...values, [key]: e.target.value }
            }}
           
              className={`${id}_${structure.field}`}
              id={`${id}_${structure.field}`}
              name={`${id}_${structure.field}`} >
              {structure.data.map((ans,i)=>
<MenuItem key={i+1} value={ans}>{ans}</MenuItem>
              )}
  </Select>
  </FormControl>
          )
          case "select":
            return (
             
              <FormControl style={{width:'50%',marginBottom:10}}>
                
    <InputLabel id="demo-simple-select-label">Category</InputLabel>
              <Select
             
              onChange={(e) => {
                
                let key = structure.field
                setItem({ ...item, [key]: e.target.value })
                values = { ...values, [key]: e.target.value }
              }}
             
                className={`${id}_${structure.field}`}
                id={`${id}_${structure.field}`}
                name={`${id}_${structure.field}`} >
                 {data.map((ans,i)=>
                 
  <MenuItem key={i+1} value={ans.Name}>{ans.Name}</MenuItem>
                )}
    </Select>
    </FormControl>
            )
      case "date":
        return (
          <TextField
            className={`${id}_${structure.field}`}
            id={`${id}_${structure.field}`}
            name={`${id}_${structure.field}`}
            type={structure.inputType}
            label={structure.headerName}
            autoComplete='false'
            variant="outlined"
            sx={{
              marginBottom: "2em",
              width: "30em",
              display: structure.hideInput ? "none" : ""
            }}
            value={item[structure.field] || currentDate}
            onChange={(e) => {
              let key = structure.field
              setItem({ ...item, [key]: e.currentTarget.value })
              values = { ...values, [key]: e.currentTarget.value }
            }}
            disabled={structure.isDisabled ? true : false}
          />
        )
      case "file":
        return (
          <div className={`${id}_${structure.field}`} style={{ marginBottom: "2em", }}>
            <Stack direction="row" spacing={2}>
              <label htmlFor={`${id}_${structure.field}`}>
                <input
                  style={{ display: "none" }}
                  type="file"
                  name={`${id}_${structure.field}`}
                  className={`${id}_${structure.field}`}
                  id={`${id}_${structure.field}`}
                  autoComplete='false'
                  onChange={(e) => {
                    let file = document.getElementById(e.currentTarget.id)
                    let nameArr = ((file.files[0] || {}).name || "").split(".")
                    let ext = nameArr[nameArr.length - 1]

                    if (!(structure.fileType || []).includes(ext)) {
                      alert("Please Upload these files -> " + structure.fileType.join(", "))
                      return false
                    }

                    let key = structure.field
                    values = { ...values, [key]: file.files[0] }
                    var tmppath = URL.createObjectURL(e.target.files[0]);
                    console.log(tmppath)
                    setFilename({ ...filename, [key]: file.files[0].name, [key + "_preview"]: tmppath })
                  }}
                />

                <Fab
                  color="secondary"
                  component="span"
                  aria-label="add"
                  variant="extended"
                >
                  <AddIcon />{structure.headerName}
                </Fab>
              </label>

              {filename[structure.field + "_preview"] ? (<Box
                sx={{
                  display: 'flex',
                  flexWrap: 'wrap',
                  '& > :not(style)': {
                    height: 120,
                    width: 120
                  },
                }}
              >
                <Paper elevation={3}>
                  <img style={{ borderRadius: "2px" }} height="120" width="120" src={filename[structure.field + "_preview"]} />
                </Paper>
              </Box>) : (<></>)}
              <Typography sx={{ margin: "auto 1em !important" }}>{filename[structure.field]}</Typography>
            </Stack>
          </div>
        )
      case "switch":
        return (
          <FormControlLabel
            control={<Switch
              className={`${id}_${structure.field}`}
              id={`${id}_${structure.field}`}
              checked={(item[structure.field] ? true : false)}
              autoComplete='false'
              onChange={(e) => {
                let key = structure.field
                setItem({ ...item, [key]: e.currentTarget.checked })
                values = { ...values, [key]: e.currentTarget.checked }
              }}
            />}
            label={structure.headerName}
            disabled={structure.isDisabled ? true : false}
            sx={{
              marginBottom: "2em",
              width: "30em"
            }}
          />
        )

      case "password":
        return (
          <FormControl
            variant="outlined"
            sx={{
              marginBottom: "2em",
              width: "30em",
              display: structure.hideInput ? "none" : ""
            }}
          >
            <InputLabel htmlFor="outlined-adornment-password">{structure.headerName}</InputLabel>
            <OutlinedInput
              className={`${id}_${structure.field}`}
              id={`${id}_${structure.field}`}
              name={`${id}_${structure.field}`}
              label={structure.headerName}
              value={item[structure.field] || ""}
              onChange={(e) => {
                let key = structure.field
                setItem({ ...item, [key]: e.currentTarget.value })
                values = { ...values, [key]: e.currentTarget.value }
              }}
              type={item["show" + structure.field] ? 'text' : 'password'}
              inputProps={{
                autoComplete: `new-${structure.field}`,
                form: {
                  autoComplete: 'off',
                },
              }}
              endAdornment={
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={(e) => {
                      e.preventDefault()
                      let key = structure.field
                      setItem({ ...item, ["show" + key]: !item["show" + key] })
                    }}
                    onMouseDown={(e) => {
                      e.preventDefault()
                    }}
                    edge="end"
                  >
                    {item.showPassword ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                </InputAdornment>
              }
            />
          </FormControl>
        )
      default:
        return (
          <></>
        )
    }
  }

  return (
    <>
      {structures[location.state.fieldKey].map((item, index) => (
        <div key={index}>
          <GetField structure={item} id={"add"} />
        </div>
      ))}

     <Loader/>

      <Button
        variant="contained"
        color="success"
        size="large"
        sx={{
          marginBottom: "2em",
        }}
        onClick={(e) => {
          handleSubmit(props, location.state.pageTitle)
        }}
      >
        ADD
      </Button>
      <Button
        variant="contained"
        color="success"
        size="large"
        sx={{
          marginBottom: "2em",
          marginLeft: "3em"
        }}
        onClick={(e) => {
          handleReset()
        }}
      >
        RESET
      </Button>
    </>
  )
}

const AddData = (props) => {
  const location = useLocation();

  useEffect(() => {
    values = {}
    fields = []
    let priority = localStorage.getItem("priority") ? Number(localStorage.getItem("priority")) : -1
    let user = localStorage.getItem("uid") ? JSON.parse(localStorage.getItem("uid")).user : {}

    structures[location.state.fieldKey].forEach((i) => {
      if (!i.hideInput)
        fields.push(i.field)
    })

    if ([2].includes(priority)) {
      values = {
        ...values,
        pid: user.id
      }
    }
    
  }, [])

  return (
    <>
      <Typography
        variant="h4"
        noWrap
        component="div"
        sx={{ marginTop: "1em", marginBottom: "1.5em" }}
      >
        {`Add ${location.state.pageTitle}`}
      </Typography>

      <GenerateFields location={location} props={props} />
    </>
  )
}

export default connect(mapStateToProps, mapDispachToProps)(AddData);